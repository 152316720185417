<template>
	<div id="rootVM">
		<div id="pageTitle">
			<b>침입탐지 설정</b>
		</div>
		<div class="contentWrap">
			<div id="treeView">
				<div class="demo-section wide k-content">
					<div class="sites" ref="sitesRef">
						<div class="site" v-for="(site, i) in siteList" :key="site.siteId" :class="i === selectedSiteIdx ? 'active' : ''" @click="clickSite(i)">
							{{ site.siteNm }}
						</div>
					</div>
				</div>
			</div>
			<div class="content">
				<div class="time-content">
					<div class="ai-config-content">
						<div>
							<label>침입 판단 기준 초</label>
							<input class="form-control" type="text" v-model="dangerJugeStandardSec" @keyup="keyupStandardSec" />
						</div>
						<button class="btn btn-primary" @click="clickSaveAiConfigBtn">
							<i class="fa fa-spin fa-spinner"></i>
							저장하기
						</button>
					</div>
					<button class="btn btn-default" @click="clickAddTimeBtn">
						<i class="fa fa-plus"></i>
						침입탐지 추가
					</button>
					<table>
						<thead>
							<tr>
								<th>침입탐지 이름</th>
								<th>시작 시간</th>
								<th>종료 시간</th>
								<th>시작일</th>
								<th>종료일</th>
								<th>등록일</th>
								<th>수정일</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(trespass, i) in trespassList" :key="trespass.trespsDtctTimeId" @click="clickTrespass(i)">
								<td>{{ trespass.trespsDtctTimeNm }}</td>
								<td>{{ trespass.trespsDtctStrtTime.replace(/(\d{2})(\d{2})/g, '$1:$2') }}</td>
								<td>{{ trespass.trespsDtctEndTime.replace(/(\d{2})(\d{2})/g, '$1:$2') }}</td>
								<td>{{ trespass.trespsDtctStrtDt.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3') }}</td>
								<td>{{ trespass.trespsDtctEndDt.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3') }}</td>
								<td>{{ trespass.regDtm.substr(0, 10) }}</td>
								<td>{{ trespass.updDtm.substr(0, 10) }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="form-popup" ref="TimeFormPopupRef">
			<div class="background" @click="closeTimeFormPopup" />
			<div class="wrapper">
				<div class="top">
					침입탐지 {{ isEditMode ? '수정' : '추가' }}
					<i class="fa fa-times" @click="closeTimeFormPopup"></i>
				</div>
				<div class="middle">
					<jarvis-field
						:label="detailsFieldMap.trespsDtctTimeNm"
						v-model="detailsItem.trespsDtctTimeNm"
						required="true"
						field="detailsItem.trespsDtctTimeNm"
						data-vv-name="detailsItem.trespsDtctTimeNm"
						v-validate="validationRule.detailsItem.trespsDtctTimeNm"
						:maxLength="maxLength.trespsDtctTimeNm"
					></jarvis-field>
					<jarvis-field
						:label="detailsFieldMap.trespsDtctStrtTime"
						v-model="detailsItem.trespsDtctStrtTime"
						type="time"
						required="true"
						field="detailsItem.trespsDtctStrtTime"
						data-vv-name="detailsItem.trespsDtctStrtTime"
						v-validate="validationRule.detailsItem.trespsDtctStrtTime"
					></jarvis-field>
					<jarvis-field
						:label="detailsFieldMap.trespsDtctEndTime"
						v-model="detailsItem.trespsDtctEndTime"
						type="time"
						required="true"
						field="detailsItem.trespsDtctEndTime"
						data-vv-name="detailsItem.trespsDtctEndTime"
						v-validate="validationRule.detailsItem.trespsDtctEndTime"
					></jarvis-field>
					<jarvis-field
						:label="detailsFieldMap.trespsDtctStrtDt"
						v-model="detailsItem.trespsDtctStrtDt"
						type="date"
						required="true"
						field="detailsItem.trespsDtctStrtDt"
						data-vv-name="detailsItem.trespsDtctStrtDt"
						v-validate="validationRule.detailsItem.trespsDtctStrtDt"
					></jarvis-field>
					<jarvis-field
						:label="detailsFieldMap.trespsDtctEndDt"
						v-model="detailsItem.trespsDtctEndDt"
						type="date"
						required="true"
						field="detailsItem.trespsDtctEndDt"
						data-vv-name="detailsItem.trespsDtctEndDt"
						v-validate="validationRule.detailsItem.trespsDtctEndDt"
					></jarvis-field>
				</div>
				<div class="bottom">
					<button v-if="isEditMode" class="btn btn-danger" @click="clickDelTimeBtn" ref="delBtnRef">
						<i class="fa fa-spin fa-spinner"></i>
						삭제하기
					</button>
					<button class="btn btn-primary" @click="clickSaveTimeBtn" ref="saveBtnRef">
						<i class="fa fa-spin fa-spinner"></i>
						저장하기
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import apiIndex from '../../api/index';
import _ from 'lodash';
import JarvisField from '@/components/JarvisField';

const siteTrespassApi = apiIndex.siteTrespassConfig;

let axiosExtention;

//상세필드검증규칙
const detailsValidationRule = {
	common: {
		trespsDtctTimeNm: 'required',
		trespsDtctStrtDt: 'required',
		trespsDtctEndDt: 'required',
		trespsDtctStrtTime: 'required',
		trespsDtctEndTime: 'required',
	},
	edit: {},
	create: {},
};

const maxLength = {
	trespsDtctTimeNm: '50',
};

_.extend(detailsValidationRule.edit, detailsValidationRule.common);
_.extend(detailsValidationRule.create, detailsValidationRule.common);

export default {
	components: {
		'jarvis-field': JarvisField,
	},
	data: () => ({
		pageParam: { loginUserSiteId: '' },
		//상세필드명맵
		detailsFieldMap: {
			trespsDtctTimeNm: '칩입탐지 이름',
			trespsDtctStrtDt: '시작일',
			trespsDtctEndDt: '종료일',
			trespsDtctStrtTime: '시작 시간',
			trespsDtctEndTime: '종료 시간',
		},
		maxLength,
		isEditMode: false,
		siteList: [],
		trespassList: [],
		tabList: [
			{
				text: '탐지 시간 설정',
				value: 'time',
			},
			{
				text: '판단 기준 설정',
				value: 'aiConfig',
			},
		],
		selectedSiteIdx: 0,
		selectedTrespassIdx: -1,
		detailsItem: {},
		dangerJugeStandardSec: 0,
	}),
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('siteTrespassConfigPageParam'));
		axiosExtention = this.$jarvisExtention.axiosExtention;

		this.siteList = this.pageParam.siteList;
		this.getOneSiteTrespassAiConfig();
		this.getOneSiteTrespassList();
	},
	watch: {
		detailsItem() {
			this.$validator.reset(); //Validation상태 초기화
		},
	},
	computed: {
		validationRule() {
			var rule = null;

			if (this.isEditMode) {
				rule = detailsValidationRule.edit;
			} else {
				rule = detailsValidationRule.create;
			}

			return { detailsItem: rule };
		},
	},
	methods: {
		// 현장 클릭
		clickSite(idx) {
			if (this.selectedSiteIdx === idx) return;
			this.selectedSiteIdx = idx;
			this.getOneSiteTrespassAiConfig();
			this.getOneSiteTrespassList();
		},
		// 버튼 로딩 중 처리
		loadingBtn() {
			document.querySelectorAll('#rootVM .btn').forEach(elem => {
				elem.classList.add('busy');
				elem.disabled = true;
			});
		},
		// 버튼 로딩 완료 처리
		loadedBtn() {
			document.querySelectorAll('#rootVM .btn').forEach(elem => {
				elem.classList.remove('busy');
				elem.disabled = false;
			});
		},
		// 특정 현장의 침입탐지 시간 리스트 가져오기
		async getOneSiteTrespassList() {
			const selectedSite = this.siteList[this.selectedSiteIdx];
			const { data } = await this.$axios.post(siteTrespassApi.inqSiteTrespassConfig, { siteId: selectedSite.siteId }).catch(axiosExtention);
			this.trespassList = data || [];
		},
		// 탐지 시간 행 클릭
		clickTrespass(idx) {
			this.selectedTrespassIdx = idx;
			const selectedTrespass = this.trespassList[this.selectedTrespassIdx];

			// DB에는 특수문자 없이 저장되고 있으므로 특수문자를 붙여서 input에 값에 사용
			this.detailsItem = {
				...selectedTrespass,
				trespsDtctStrtDt: selectedTrespass.trespsDtctStrtDt.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
				trespsDtctEndDt: selectedTrespass.trespsDtctEndDt.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
				trespsDtctStrtTime: selectedTrespass.trespsDtctStrtTime.replace(/(\d{2})(\d{2})/g, '$1:$2'),
				trespsDtctEndTime: selectedTrespass.trespsDtctEndTime.replace(/(\d{2})(\d{2})/g, '$1:$2'),
			};

			this.isEditMode = true;
			this.openTimeFormPopup();
		},
		// 새 침입탐지 추가
		clickAddTimeBtn() {
			this.isEditMode = false;
			this.openTimeFormPopup();
		},
		// 추가/수정 팝업 열기
		openTimeFormPopup() {
			document.body.style.overflow = 'hidden';
			this.$refs.TimeFormPopupRef.classList.add('open');
		},
		// 탐지 시간 추가/수정 팝업 닫기
		closeTimeFormPopup() {
			document.body.style.overflow = 'auto';
			this.$refs.TimeFormPopupRef.classList.remove('open');
			this.detailsItem = {};
		},
		// 탐지 시간 저정하기 버튼 클릭
		clickSaveTimeBtn() {
			const closure = this;

			closure.$validator.validateAll().then(async success => {
				if (success == false) {
					return alert('입력란을 다시 확인해 주세요');
				}

				const strtDt = new Date(closure.detailsItem.trespsDtctStrtDt);
				const endDt = new Date(closure.detailsItem.trespsDtctEndDt);

				if (strtDt > endDt) {
					return alert('종료일을 시작일 이후로 설정해 주십시오');
				}

				if (confirm('저장하시겠습니까?')) {
					closure.loadingBtn();

					// Edit 모드에 따라 URL 변경
					const url = closure.isEditMode ? siteTrespassApi.updSiteTrespassConfig : siteTrespassApi.insSiteTrespassConfig;

					// DB에는 특수문자 없이 저장되고 있으므로 특수문자를 제거하여 API에 전송
					const data = {
						...closure.detailsItem,
						siteId: closure.siteList[closure.selectedSiteIdx].siteId,
						trespsDtctStrtDt: closure.detailsItem.trespsDtctStrtDt.replace(/-/g, ''),
						trespsDtctEndDt: closure.detailsItem.trespsDtctEndDt.replace(/-/g, ''),
						trespsDtctStrtTime: closure.detailsItem.trespsDtctStrtTime.replace(':', ''),
						trespsDtctEndTime: closure.detailsItem.trespsDtctEndTime.replace(':', ''),
					};

					await closure.$axios
						.post(url, data)
						.then(() => {
							closure.getOneSiteTrespassList();
							closure.closeTimeFormPopup();
						})
						.catch(axiosExtention.buildErrorHandler(closure.detailsFieldMap));

					closure.loadedBtn();
				}
			});
		},
		// 탐지 시간 삭제하기 버튼 클릭
		async clickDelTimeBtn() {
			if (confirm('삭제하시겠습니까?')) {
				this.loadingBtn();

				const selectedTrespass = this.trespassList[this.selectedTrespassIdx];

				await this.$axios
					.post(siteTrespassApi.delSiteTrespassConfig, { trespsDtctTimeId: selectedTrespass.trespsDtctTimeId })
					.then(() => {
						this.getOneSiteTrespassList();
						this.closeTimeFormPopup();
					})
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));

				this.loadedBtn();
			}
		},
		// 특정 현장의 침입탐지 판단 기준 가져오기
		async getOneSiteTrespassAiConfig() {
			const selectedSite = this.siteList[this.selectedSiteIdx];
			const { data } = await this.$axios.post(siteTrespassApi.inqSiteTrespassAiConfig, { siteId: selectedSite.siteId });
			this.dangerJugeStandardSec = data.dangerJugeStandardSec;
		},
		// 판단 기준 초 입력 시 정수 이외 제거
		keyupStandardSec() {
			const replacedVal = this.dangerJugeStandardSec.toString().replace(/[^0-9]/g, '');
			this.dangerJugeStandardSec = replacedVal;
		},
		// 판단 기준 초 저장하기 버튼 클릭 시
		async clickSaveAiConfigBtn() {
			const sec = Number(this.dangerJugeStandardSec); // 판단기준초

			if (!sec) {
				return alert('판단 기준 초 입력값을 다시 확인해주세요');
			}

			if (sec < 1) {
				return alert('침입 판단 기준 초는 최소 1초 이상이어야 합니다');
			}

			if (confirm('저장하시겠습니까?')) {
				this.loadingBtn();

				const selectedSite = this.siteList[this.selectedSiteIdx];

				await this.$axios
					.post(siteTrespassApi.updSiteTrespassAiConfig, { dangerJugeStandardSec: this.dangerJugeStandardSec, siteId: selectedSite.siteId })
					.catch(axiosExtention.buildErrorHandler());

				this.loadedBtn();
			}
		},
	},
};
</script>

<style>
#rootVM .btn {
	display: flex;
	align-items: center;
	justify-content: center;
}

#rootVM .btn .fa-spin {
	display: none;
	margin-right: 4px;
}

#rootVM .btn.busy .fa-spin {
	display: block;
}

.panel-body {
	text-align: center;
	margin-bottom: 10px !important;
}

.in-panel-body {
	display: inline-block;
	width: 1400px;
}

.contentWrap {
	display: flex;
	margin-bottom: 60px;
}

.contentWrap > div {
	border: 1px solid #ddd;
}

.contentWrap #treeView {
	width: 250px;
	height: 740px;
	margin-right: 1%;
	font-size: 15px;
	font-weight: bold;
	line-height: 15px;
	padding-top: 15px;
}

.contentWrap .sites {
	display: flex;
	flex-direction: column;
	color: #333333;
	font-size: 15px;
	padding-left: 10px;
}

.contentWrap .sites .site {
	width: fit-content;
	border-radius: 4px;
	border: 1px solid transparent;
	cursor: default;
	padding: 4px 8px;
}

.contentWrap .sites .site:hover {
	border: 1px solid #eee;
	background: rgb(183, 190, 201);
}

.contentWrap .sites .site.active {
	background: #1984c8;
	color: white;
}

.contentWrap > .content {
	height: 740px;
	padding: 10px;
}

.contentWrap .tabs {
	display: flex;
}

.contentWrap .tabs > span {
	border: 1px solid #ddd;
	cursor: pointer;
	padding: 6px 14px;
}

.contentWrap .tabs > span.active {
	background: #1984c8;
	color: white;
	font-weight: bold;
}

.contentWrap .tabs > span:first-child {
	border-right: 0;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.contentWrap .tabs > span:last-child {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.contentWrap .content .time-content .btn {
	float: right;
	margin-top: 20px;
	margin-bottom: 10px;
}

.contentWrap .content .time-content .btn .fa-plus {
	margin-right: 4px;
}

.contentWrap .content .time-content table {
	width: 100%;
	font-size: 14px;
	margin-top: 20px;
}

.contentWrap .content .time-content table thead th {
	border-bottom: 1px solid #ddd;
	text-align: center;
	font-weight: bold;
	width: 13.5%;
	padding: 6px;
}

.contentWrap .content .time-content table tbody {
	position: relative;
}

.contentWrap .content .time-content table tbody:empty:after {
	content: '조회 결과가 없습니다';
	position: absolute;
	left: calc(50% - 100px);
	width: 100%;
	font-size: 14px;
	color: gray;
	padding-top: 100px;
}

.contentWrap .content .time-content table tbody tr:hover {
	background: #ddd;
	cursor: pointer;
}

.contentWrap .content .time-content table tbody td {
	text-align: center;
	width: 13.5%;
	padding: 6px;
}

.contentWrap .content .time-content table tbody td:nth-child(1) {
	text-align: left;
	max-width: 100px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.contentWrap .content .ai-config-content {
	display: flex;
}

.contentWrap .content .ai-config-content input {
	max-width: 120px;
}

.contentWrap .content .ai-config-content button.btn {
	float: unset;
	margin-top: auto;
	margin-bottom: 0;
	margin-left: 8px;
}

.form-popup {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.form-popup.open {
	display: flex;
}

.form-popup .background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	z-index: -1;
}

.form-popup .wrapper {
	width: 30vw;
	background: white;
	margin: auto;
	z-index: 10;
	display: flex;
	flex-direction: column;
	border: 1px solid #eee;
	border-radius: 6px;
	overflow: hidden;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.form-popup .wrapper > div {
	flex: 1;
	display: flex;
	align-items: center;
	padding: 12px;
}

.form-popup .wrapper .top {
	height: 50px;
	max-height: 50px;
	font-size: 1.2em;
	font-weight: bold;
	border-bottom: 1px solid #ddd;
}

.form-popup .wrapper .top .fa-times {
	font-size: 1.4em;
	margin-left: auto;
	margin-right: 10px;
	cursor: pointer;
}

.form-popup .wrapper .middle {
	border-bottom: 1px solid #ddd;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.form-popup .wrapper .middle > .form-group {
	display: flex;
	width: 100%;
}

.form-popup .wrapper .middle > .form-group:not(:first-child) > :last-child {
	max-width: 160px;
}

.form-popup .wrapper .middle > .form-group .control-label {
	width: 120px;
	text-align: left;
	margin: auto 0;
}

.form-popup .wrapper .middle > .form-group .validation-error-label {
	display: none;
}

.form-popup .wrapper .bottom {
	height: 60px;
	max-height: 60px;
}

.form-popup .wrapper .bottom .btn-primary {
	margin-left: auto;
}
</style>
